export const languages = [
  { value: "albanian", label: "Albanés" },
  { value: "german", label: "Alemán" },
  { value: "arabic", label: "Árabe" },
  { value: "armenian", label: "Armenio" },
  { value: "algerian", label: "Argelino" },
  { value: "bambara", label: "Bambara" },
  { value: "bengali", label: "Bengalí" },
  { value: "berber", label: "Bereber" },
  { value: "belarusian", label: "Bielorruso" },
  { value: "bosnian", label: "Bosnio" },
  { value: "bulgarian", label: "Búlgaro" },
  { value: "brazilian", label: "Brasileño" },
  { value: "catalan", label: "Catalán" },
  { value: "czech", label: "Checo" },
  { value: "chinese", label: "Chino" },
  { value: "korean", label: "Coreano" },
  { value: "croatian", label: "Croata" },
  { value: "danish", label: "Danés" },
  { value: "slovak", label: "Eslovaco" },
  { value: "slovenian", label: "Esloveno" },
  { value: "spanish", label: "Español" },
  { value: "estonian", label: "Estonio" },
  { value: "farsi", label: "Farsi" },
  { value: "finnish", label: "Finés/Finlandés" },
  { value: "flemish", label: "Flamenco" },
  { value: "french", label: "Francés" },
  { value: "irish", label: "Irlandés" },
  { value: "georgian", label: "Georgiano" },
  { value: "greek", label: "Griego" },
  { value: "guarani", label: "Guaraní" },
  { value: "dutch", label: "Holandés/Neerlandés" },
  { value: "hungarian", label: "Húngaro" },
  { value: "english", label: "Inglés" },
  { value: "indonesian", label: "Indonesio" },
  { value: "iranian", label: "Iraní" },
  { value: "iraquian", label: "Iraquí" },
  { value: "italian", label: "Italiano" },
  { value: "japanese", label: "Japonés" },
  { value: "kurdish_kurmanji", label: "Kurdo kurmanji" },
  { value: "kurdish_sorani", label: "Kurdo sorani" },
  { value: "latvian", label: "Letón" },
  { value: "lithuanian", label: "Lituano" },
  { value: "macedonian", label: "Macedonio" },
  { value: "maninka", label: "Maninka" },
  { value: "moldovan", label: "Moldavo" },
  { value: "mongol", label: "Mongol" },
  { value: "norwegian", label: "Noruego" },
  { value: "pakistani", label: "Pakistaní" },
  { value: "persian", label: "Persa" },
  { value: "polish", label: "Polaco" },
  { value: "portuguese", label: "Portugués" },
  { value: "punjabi", label: "Punjabí" },
  { value: "riffian", label: "Rifeño" },
  { value: "romanian", label: "Rumano" },
  { value: "russian", label: "Ruso" },
  { value: "senegalese", label: "Senegalés" },
  { value: "serbian", label: "Serbio" },
  { value: "sign_language", label: "Lengua de signos" },
  { value: "soninke", label: "Soninke" },
  { value: "swedish", label: "Sueco" },
  { value: "swiss", label: "Suizo" },
  { value: "syrian", label: "Sirio" },
  { value: "thai", label: "Tailandés" },
  { value: "turkish", label: "Turco" },
  { value: "twi", label: "Twi" },
  { value: "ukrainian", label: "Ucraniano" },
  { value: "urdu", label: "Urdu" },
  { value: "vietnamese", label: "Vietnamita" },
  { value: "wolof", label: "Wolof" },
];

export default languages;